<template>
  <Transition>
  <div v-if="!is_ready" class="busy-loading">
    <img src="@/assets/imageedit_8_5039038813.png" />
  </div>
  </Transition>
  <div class="root" v-if="!is_authenticated && is_ready">
    <Login />
  </div>
  <div class="root" v-if="is_authenticated && is_ready">
    <Navigation />
    <section class="content">
      <RouterView :user="name">
      </RouterView>
    </section>
  </div>
</template>


<script>
import { mapState } from 'pinia'
import { useLoginStore } from '@/stores/login'
import { useAppStore } from '@/stores/app'

import Login from "./components/Login.vue";
import Navigation from "./components/Navigation.vue";
export default {
  name: 'App',
  components: {
    Login,
    Navigation
  },
  data() {
    return {
      auth: false,
      route: false,
    }
  },
  computed: {
    ...mapState(useLoginStore, {
      is_authenticated: 'auth',
    }),
    ...mapState(useAppStore, {
      is_ready: 'loaded',
    }),    

    ...mapState(useLoginStore, {
      name: 'name',
    })  },

    mounted() {
      this.route = this.$route.name;
    },
  setup() {
    const authentication = useLoginStore()
    const app = useAppStore()
    console.log("hasAuth: ",authentication.auth);
    app.initialize();
  }
}
</script>

<style>
:root {
  --primary-color: #a90a2e;
  --secondary-color: rgba(169, 10, 47, 0.894);

  --nav-active-link-bg: #323232;
  --nav-width: 250px;
  --nav-text-color: #fff;
}

* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
}
.root {
  display: flex;
}
body {
  margin: 0px;
  padding: 0px;
}

nav {
  width: var(--nav-width);
  height: 100vh;

  overflow-x: hidden;
  transition: 0.5s;
  
  background-color: var(--primary-color);
  color: var(--nav-text-color);

  display: flex;
  flex-direction: column;
  user-select: none;

  flex: 0 0 var(--nav-width);

}


h1,h2,h3,h4 {
  color: var(--primary-color);
}

nav .logo {
    display: flex;
    height: 50px;
    user-select: none;
    margin-bottom: 30px;

    height: 80px;
}
nav .logo img {
  width: 80%;
  margin: auto;
}


.content {
  background-color: rgba(0,0,0, 0.1);
  width: calc(100% - var(--nav-width));
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  flex: 3;
}
.content-header {
  height: 45px;
  display: flex;
  flex-direction: row;
  user-select: none;
}
.page-header {
  background-color: var(--primary-color);
  line-height: 45px;
  line-height: 45px;
  width: calc(100% - 250px);color: var(--nav-text-color);
  padding-left: 15px;
}
.login {
  width: 250px;
  line-height: 45px;
  background-color: var(--primary-color);
  text-align: right;
  padding-right: 15px;
  color: var(--nav-text-color);
}
.real-content {
  padding: 15px;
  overflow: auto;
}


.nav {
  padding-left: 25px;
  height: calc(100vh - 160px);
}
nav footer {
  height: 50px;
  display: flex;
  font-size: 10pt;
  text-align: left;
}
nav footer  {
  margin: auto;
  width: 100%;
  line-height: 45px;
  display: flex;
}

.user {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.user .alias {
  width: calc(100% - 50px);
  padding-left: 22px;
}

.user .action svg {
  height: 15px;
  width: 15px;
  cursor: pointer;
  fill: #fff !important;
}

ul {
  list-style: none;;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
}
li {
  padding: 15px;
}
nav ul li:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.active {
  background-color: var(--nav-active-link-bg);
}
.active:hover {
  background-color: var(--nav-active-link-bg);
}
.nav-section-header {
  font-weight: bold;
  text-transform: uppercase;
}

.busy-loading {
  background-color: var(--primary-color);;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 10000;
  position: absolute;
  top: 0px;
  left: 0px;
  user-select: none;
}
.busy-loading img {
  margin: auto;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
