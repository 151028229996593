import { defineStore } from "pinia";
import axios from "axios";

export const useCounterStore = defineStore("counter", {
  state: () => {
    return {
      auth: false,
      invoices: [],
    };
  },
  mounted() {
    alert("OK");
  },
  actions: {
    async initialize() {
      const { data } = await axios.get(
        "https://jsonplaceholder.typicode.com/todos"
      );
      this.$patch({ invoices: data });
    },
  },
});
