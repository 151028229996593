import { defineStore } from "pinia";
import axios from "axios";
export const useAppStore = defineStore("app", {
  state: () => {
    return {
      version: null,
      loaded: false,
      users: [],
    };
  },
  actions: {
    async initialize() {
      const { data } = await axios.get(
        "https://gist.githubusercontent.com/reisenbauer/03783a894efa37601b10faa7cdcee917/raw/bmg.json?t=" +
          Date.now()
      );
      if (data.enabled)
        this.$patch({
          version: data.version,
          users: data.users,
          loaded: true,
        });
    },
  },
});
