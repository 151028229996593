import { createWebHashHistory, createRouter } from "vue-router";

import { useLoginStore } from "@/stores/login";

import Welcome from "../components/Welcome.vue";
import Rechnungen from "../components/Rechnungen.vue";
import System from "../components/system/index.vue";
import TBDRoute from "../components/tbd.vue";

const routes = [
  {
    path: "/",
    component: Welcome,
    name: "home",
    title: null,
    meta: {
      protected: true,
      visible: true,
      nav_name: "Dashboard",
      group: "Dashboard",
      group_id: 0,
      title: "Dashboard",
    },
  },
  {
    path: "/invoices",
    component: Rechnungen,
    name: "rechnungen",
    protected: true,
    meta: {
      protected: true,
      visible: true,
      nav_name: "Rechnungen",
      group: "Rechnungen",
      group_id: 1,
      title: "Rechnungen",
    },
  },

  // Settings
  {
    path: "/settings/regex",
    component: TBDRoute,
    name: "settings_regex",
    meta: {
      protected: true,
      visible: true,
      nav_name: "REGEX Regeln",
      group: "Einstellungen",
      group_id: 2,
      title: "Einstellungen > REGEX Regeln",
    },
  },

  // Reporting
  {
    path: "/reporting/health",
    component: System,
    name: "reporting_health",
    protected: true,
    meta: {
      protected: true,
      visible: true,
      nav_name: "System Status",
      group: "Reporting",
      group_id: 3,
      title: "System Status",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const login = useLoginStore();
  const { protected: is_protected = false } = to.meta;
  if (is_protected && login.auth == false) {
    return next("/login");
  } else {
    return next();
  }
});
export default router;
