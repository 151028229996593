import { defineStore } from "pinia";
import axios from "axios";

import { useAppStore } from "./app";

export const useLoginStore = defineStore("login", {
  state: () => {
    return {
      auth: false,
      name: null,
    };
  },
  actions: {
    login(username, password) {
      return new Promise((resolve) => {
        const app_config = useAppStore();
        const auth_user = app_config.users.find(
          (e) => e.username == username && e.password == password
        );
        if (auth_user) {
          this.$patch({
            auth: true,
            name: auth_user.name,
          });
          return resolve({
            name: auth_user.name,
          });
        } else {
          return resolve(false);
        }
      });
    },
    logout() {
      this.$patch({
        auth: false,
        name: null,
      });
    },
    async getInvoices() {
      const { data } = await axios.get(
        "https://jsonplaceholder.typicode.com/todos"
      );
      this.$patch({ invoices: data });
    },
  },
});
