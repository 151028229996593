<template class="vvv">
    <div class="vvv">
        <h1>TBD</h1>
        Die Funktion <b>{{ this.$route.meta.title }}</b> steht aktuell noch nicht zur Verf&uuml;gung,<br />
        wir arbeiten aber bereits daran!
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        user: String,
    },
    data() {
        return {
        }
    },
}
</script>
<style scoped>
.vvv {
    border: 1px solid red;
}
</style>