<template>
    <div>
        <h1>System Status</h1>
        <table>
            <tr>
                <td width="300"><b>Software Version:</b></td>
                <td>{{ version }}</td>
            </tr>
        </table>

    </div>
</template>
<script>
import { mapState } from 'pinia'
import { useAppStore } from '../../stores/app'
export default {
    data() {
        return {
        }
    },
    mounted() {
    },
    computed: {
        ...mapState(useAppStore, ['version'])

    },
    methods: {
    },
}
</script>
