<template>
    <h1>Hi {{ user.split( " ")[0] }}, sch&ouml;n dich zu sehen!</h1>
</template>
<script>
export default {
    props: {
        user: String,
    },
    data() {
        return {
        }
    },
}
</script>