<template>
    <div class="root">
        <div class="login-window" :class="{ 'wobble animated': auth_fail }">
            <div class="login-header">
                <img src="@/assets/logo.png" />
            </div>
            <div class="login-form">
                <div class="login-form-text">
                </div>

                <form autocomplete="off">

                    <input v-model="username" ref="username" autofocus type="text" data-lpignore="true"
                        autocomplete="off" placeHolder="michael.kronbichler@brandl-bau.at" /><br />
                    <input v-model="password" type="password" placeHolder="Windows Kennwort" /><br />

                    <input type="button" value="Anmelden" v-on:click="login()" />
                </form>

            </div>

            <div class="login-submit">
                <div v-if="version"><b>Version:</b> {{ version }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'pinia'
import { useLoginStore } from '@/stores/login'
import { useAppStore } from '@/stores/app'
export default {
    data() {
        return {
            auth_fail: false,
            username: null,
            password: null,
        }
    },
    mounted() {
        this.$refs.username.focus();
    },
    computed: {
        ...mapState(useAppStore, {
            version: 'version',
        }),        
    },
    methods: {
        async login() {
            const authentication = useLoginStore()
            authentication.login(this.username, this.password)
            .then((e) => {
                if (e) {
                    this.$router.push({
                        path: '/',
                    })
                } else {
                    this.auth_fail = true;
                    setTimeout(() => {
                        this.auth_fail = false;
                        this.$refs.username.focus();
                    }, 1000)
                }
            })
        }
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
    font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
body, html {
    overflow: hidden !important;
}
.root {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;

    background: url(@/assets/brandl-bg.png);
    background-size: cover;
}


.app-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0.5;
}

.login-window {
    margin: auto;
    background-color: var(--primary-color);
    border: 10px solid var(--primary-color);
    width: 400px;
    overflow: hidden;

    border-radius: 10px;
    z-index: 1000;
    
}


@keyframes mymove {
    0% { box-shadow: 0px 0px 10x var(--primary-color); }
    20% { box-shadow: 0px 0px 30px var(--primary-color); }
    50% { box-shadow: 0px 0px 50px var(--primary-color);}
    100% { box-shadow: 0px 0px 10px var(--primary-color); }
}


.login-header {
    display: flex;
    height: 100px;
    background-color: #fff;
}
.login-header img {
    width: 80%;
    margin: auto;
}

.login-form {
    padding: 15px;
    background-color: var(--primary-color);
    color: #fff;
}
input {
    border-radius: 0px !important;
    padding: 15px;
    margin-bottom: 15px;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.1);
    width: 100%;
    color: #fff;
}
input:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
}
input:focus {
    outline: none !important;border: 1px solid rgba(255, 255, 255, 0.5);
}

input::placeholder {
    color: #fff;
}

.login-form-text {
    margin-bottom: 15px;
    font-weight: normal !important;
}
.login-submit {
    background-color: var(--primary-color);
    height: 45px;
    display: flex;
}
.login-submit div {
    margin: auto;
    color: #fff;
    font-size: 0.8rem;
}

input[type="button"] {
    cursor: pointer;
}




.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        -ms-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        -ms-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        -ms-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        -ms-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        -ms-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    100% {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble
}
</style>