<script setup>
import { useCounterStore } from '@/stores/counter'

const counter = useCounterStore()
counter.initialize();

</script>

<template>
  <h1>Rechnungen</h1>
  <div>Current Count: {{ counter.invoices }}</div>
</template>