<template>
    <nav>

        <div class="logo">
            <img src="@/assets/imageedit_8_5039038813.png" />
        </div>
        <div class="nav">
            <section v-for="group in menu" v-bind:key="group">
                <div class="nav-section-header">{{ group.name }}</div>
                <ul>
                    <li :class="{ 'active': this.$route.name == i.name }" v-for="i in getFilteredRoutes(group)"
                        v-bind:key="i">
                        <RouterLink :to="i.path">
                            {{ i.meta.nav_name ? i.meta.nav_name : "xx" }}
                        </RouterLink>
                    </li>
                </ul>
            </section>



            <!--
            <div class="nav-section-header">Rechnungen</div>
            <ul>
                <li :class="{ 'active': this.$route.name == 'rechnungen' }">
                    <RouterLink to="/invoices">&Uuml;bersicht</RouterLink>
                </li>
            </ul>
            <div class="nav-section-header">Einstellungen</div>
            <ul>
                <li :class="{ 'active': this.$route.name == 'settings_regex' }">
                    <RouterLink to="/settings/regex">Regex Regeln</RouterLink>
                </li>
            </ul>
            <div class="nav-section-header">Reporting</div>
            <ul>
                <li :class="{ 'active': this.$route.name == 'reporting_health' }">
                    <RouterLink to="/reporting/health">System Status</RouterLink>
                </li>
            </ul>
            -->
        </div>

        <footer>
            <div class="user">
                <div class="alias">{{ name }}</div>
                <div class="action" v-on:click="logout()" title="Abmelden">
                    <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 384.971 384.971" xml:space="preserve">
                        <g>
                            <g id="Sign_Out">
                                <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
			C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
			C192.485,366.299,187.095,360.91,180.455,360.91z" />
                                <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
			c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
			c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z" />
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </footer>
    </nav>
</template>

<script>
import { mapState } from 'pinia'
import { useLoginStore } from '@/stores/login'
export default {
    name: 'App',
    components: {
    },
    computed: {
        ...mapState(useLoginStore, {
            name: 'name',
        }),
        menu() {
            const routes = this.$router.getRoutes();
            const groups = routes.reduce((m, r) => {
                const { group = null, visible = false, group_id = 0 } = r.meta;
                if (visible) {
                    const v = {
                        name: group,
                        id: group_id,
                    }
                    if(!m.includes(v)) m.push(v);
                }
                return m;
            }, [])
            return groups.sort(function (a, b) {
                return a.id - b.id
            });
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        getFilteredRoutes(f) {
            const routes = this.$router.getRoutes();
            return routes.filter(e => e.meta && e.meta.group && e.meta.group == f.name);
        },
        logout() {
            const store = useLoginStore();
            store.logout();
        }
    },
    mounted() {
        this.route = this.$route.name;
    }
}
</script>